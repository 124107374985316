<template>
  <default-dialog
    :show="show"
    :width="500"
    title="Status de los servicios"
    @close="$emit('close')"
  >
    <div>
      <p>
        A continuación se muestran los status que están disponibles a usar en el
        negocio. Puedes modificarlos, eliminarlos y agregar nuevos.
      </p>

      <div>
        <v-skeleton-loader v-if="loading" type="list"></v-skeleton-loader>
        <v-list v-else>
          <v-list-item
            v-for="statusListItem in status"
            :key="statusListItem._id"
          >
            <v-list-item-avatar>
              <v-chip :color="statusListItem.colorCode" label></v-chip>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ statusListItem.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
  </default-dialog>
</template>

<script>
import DefaultDialog from "../../layout/defaults/DefaultDialog.vue";
import serverRequestMixin from "../../../mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  components: {
    "default-dialog": DefaultDialog,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      status: [],
    };
  },

  mounted() {
    this.fetchStatus();
  },

  methods: {
    async fetchStatus() {
      this.loading = true;

      try {
        const serverResponse = await this.getRequest("/status");
        this.status = serverResponse.status;
        console.log({ status: this.status });
      } catch (error) {
        console.error(error);
        if (error.response.status == 400)
          this.error = error.response.data.message;
        else {
          this.error = "Error inesperado, por favor contacta al desarrollador";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
