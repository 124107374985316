<template>
  <v-dialog v-model="show" persistent :max-width="width">
    <v-card>
      <v-toolbar dense dark flat tile color="secondary">
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-2">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
};
</script>
